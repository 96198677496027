<template>
  <div class="userManage bgblue">
    <!-- 客户数量、待结安装费等 -->
    <div class="manage">
      <el-row :gutter="7">
        <el-col :span="6">
          <el-card shadow="hover" class="cardUser">
            <div class="cardUserItem">
              <div class="imgAll" style="flex: 0.3">
                <img style="width: 30px" src="@/assets/images/agent/u1.png" />
              </div>
              <div style="flex: 1">
                <div style="font-size: 20px; font-weight: bold">客户数量</div>
                <div class="cardUserItemshu">
                  <div style="flex: 2">
                    <div class="commonUser">已交付客户数量</div>
                    <div class="commonUser">正在跟进客户数量</div>
                    <div class="commonUser">意向客户</div>
                  </div>
                  <div style="flex: 1; margin-left: 20px">
                    <div>
                      <div class="commonUser">
                        {{ khcountObj[0] ? khcountObj[0][1].life :"" }}
                      </div>
                      <div class="commonUser">
                        {{  khcountObj[1] ? khcountObj[1][0].follow_up :"" }}
                      </div>
                      <div class="commonUser">
                        {{   khcountObj[0] ? khcountObj[0][0].life :"" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="hover" class="cardUser">
            <div class="cardUserItem">
              <div class="imgAll" style="flex: 0.3">
                <img style="width: 30px" src="@/assets/images/agent/u2.png" />
              </div>
              <div>
                <div style="font-size: 20px; font-weight: bold">待结安装费</div>
                <div class="cardUserItemshu">
                  <div style="flex: 3">
                    <div class="commonUser">待确认金额</div>
                    <div class="commonUser">代开票金额</div>
                    <div class="commonUser">待支付金额</div>
                  </div>
                  <div style="flex: 0.4; margin-left: 20px">
                    <div>
                      <div class="commonUser">
                        {{ DzzfObj[0] ? DzzfObj[0][0].dqr :"" }}
                      </div>
                      <div class="commonUser">
                        {{ DzzfObj[1] ? DzzfObj[1][0].bkp : "" }}
                      </div>
                      <div class="commonUser">
                        {{ DzzfObj[2]? DzzfObj[2][0].dzf :"" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="hover" class="cardUser">
            <div class="cardUserItem">
              <div class="imgAll" style="flex: 0.3">
                <img style="width: 30px" src="@/assets/images/agent/u3.png" />
              </div>
              <div style="flex: 1.2">
                <div style="font-size: 20px; font-weight: bold">代理商数量</div>
                <div class="cardUserItemshu" style="width: 100%">
                  <div style="flex: 1.2">
                    <div class="commonUser">代理商总数</div>
                    <div class="commonUser">本月新增代理商</div>
                  </div>
                  <div style="flex: 0.8; margin-left: 20px; width: 100%">
                    <div>
                      <div class="commonUser">
                        {{ dlcountObj.total_count | finance }}
                      </div>
                      <div class="commonUser">
                        {{ dlcountObj.filtered_count | finance }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>

        <el-col :span="6">
          <el-card shadow="hover" class="cardUser">
            <div class="cardUserItem">
              <div class="imgAll" style="flex: 0.3">
                <img style="width: 30px" src="@/assets/images/agent/u4.png" />
              </div>
              <div style="flex: 1.2">
                <div style="font-size: 20px; font-weight: bold">电站数据</div>
                <div class="cardUserItemshu">
                  <div style="flex: 2">
                    <div class="commonUser">电站装机总量</div>
                    <div class="commonUser">电站总数</div>
                  </div>
                  <div style="flex: 1; margin-left: 20px">
                    <div>
                      <div class="commonUser">
                        {{ dscountObj.zongrongliang | finance }}
                      </div>
                      <div class="commonUser">
                        {{ dscountObj.count | finance }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- 快捷入口 -->
    <div class="uickAccess">
      <el-card shadow="never" class="uickAccessCard">
        <div style="font-size: 18px; font-weight: bold">快捷入口</div>
        <div style="display: flex">
          <el-card
            class="uickAccessItem"
            style="padding: 0px"
            v-for="item ,index in quickAccessList"
            :key="index"
          >
            <div style="display: flex" @click="toOthers(item.url)">
              <img style="width: 40px; height: 40px" :src="item.imge" alt="" />
              <div
                style="
                  margin-top: 10px;
                  margin-left: 20px;
                  font-size: 14px;
                  text-align: center;
                "
              >
                {{ item.value }}
              </div>
            </div>
          </el-card>
        </div>
      </el-card>
    </div>
    <div class="statisticalChart">
      <el-card class="salesStatement">
        <div>销售报表</div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="提货数量" name="first"></el-tab-pane>
          <el-tab-pane label="提货规模" name="second"></el-tab-pane>
          <div id="chart" style="width: 100%; height: 400px"></div>
        </el-tabs>
        <div class="dateYue">
          <div style="display: flex">
            <el-link
              :underline="false"
              @click="changeDate(2)"
              class="dateitem"
              :class="dateFlag ? 'active' : ''"
              >天</el-link
            >
            <el-link
              :underline="false"
              @click="changeDate(1)"
              class="dateitem"
              :class="!dateFlag ? 'active' : ''"
              >月</el-link
            >
          </div>
          <el-date-picker
            style="width: 350px"
            @blur="changeTime"
            v-show="dateFlag"
            v-model="salesObj.starttime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-date-picker
            style="width: 350px"
            @blur="changeTime"
            v-show="!dateFlag"
            v-model="salesObj.Endtime"
            type="monthrange"
            size="mini"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
          >
          </el-date-picker>
        </div>
      </el-card>
      <el-card class="customerStatistics">
        <div>客户统计</div>
        <div id="pieId" style="width: 90%; height: 400px"></div>
      </el-card>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      activeName: "first",
      radio1: "",
      quickAccessList: [
        {
          imge: require("@/assets/images/agent/u27.png"),
          value: "客户列表",
          url: "/userManage/dataCompletion",
        },
        {
          imge: require("@/assets/images/agent/u28.png"),
          value: "代理商列表",
          url: "/agentManagement/agentManagement",
        },
        {
          imge: require("@/assets/images/agent/u29.png"),
          value: "审核",
          url: "/prospecying",
        },
        {
          imge: require("@/assets/images/agent/u30.png"),
          value: "备忘录",
          url: "/memorandum",
        },
        {
          imge: require("@/assets/images/agent/u31.png"),
          value: "财务",
          url: "/bill",
        },
      ],

      dlcountObj: {}, // 代理商数量
      khcountObj: [], // 客户数量
      dscountObj: {}, // 电站数据

      salesObj: {}, // 销售报表

      dateFlag: false,
      dayandmonth: 1,
      khzyObj: {}, // 客户统计
      getDzcountsmList: [],
      DzzfObj: {},
      flag: 0,
    };
  },
  created() {
    this.getKhcount();
    this.getDlcount();
    this.getDscount();
    this.getkhzy();
    this.getDzcountsm();
    this.getDzcounmoy();
    this.getDzzf();
  },
  mounted() {
    setTimeout(() => {
      this.drawChart();
      this.pieChart();
    }, 1000);
  },
  filters: {
    finance: function (value) {
      if (!value) return "";
      console.log(value, "ppppppppp");
    },
  },
  methods: {
    handleClick(tab, event) {
      this.flag = tab.index;
      if (this.flag == 0) {
        this.getDzcountsm();
      } else {
        this.getDzcounmoy();
      }
    },
    changeDate(value) {
      this.dateFlag = !this.dateFlag;
      this.dayandmonth = value;
      if (this.flag == 0) {
        this.getDzcountsm();
      } else {
        this.getDzcounmoy();
      }
    },
    changeTime() {
      if (this.flag == 0) {
        this.getDzcountsm();
      } else {
        this.getDzcounmoy();
      }
    },
    toOthers(url) {
      console.log(url);
      this.$router.push({ path: url });
    },
    // 折线图方法
    drawChart() {
      let dataX = this.getDzcountsmList.map((res) => {
        return res.month;
      });
      let dataY = this.getDzcountsmList.map((res) => {
        return res.count || res.price;
      });
      // 创建一个新的Echarts实例
      const chart = echarts.init(document.getElementById("chart"));
      // 设置选项
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dataX,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: dataY,
            type: "line",
            lineStyle: {
              color: "#294ED4", // 设置折线的颜色为红色
              width: 2, // 设置折线的宽度为2像素
              type: "solid", // 设置折线的类型为实线，默认值
            },
            axisLine: {
              lineStyle: {
                type: "dashed", // 设置 Y 轴为虚线
              },
            },
          },
        ],
      };
      option && chart.setOption(option);
    },
    // 饼状图方法
    pieChart() {
      let allAmount =
        this.khcountObj[1][0].follow_up +
        this.khcountObj[0][1].life +
        this.khcountObj[0][0].life;
      let chartDom = document.getElementById("pieId");
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          // 设置图例为垂直排列
          orient: "vertical",
          // 可以设置图例的位置
          left: "right",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: ["跟进中客户", "已完成客户", "意向客户"],
            color: ["blue", "#BAC6F1", "#D7D7D7"],
            labelLine: {
              show: false,
            },
            data: [
              { value: this.khcountObj[1][0].follow_up, name: "跟进中客户" },
              { value: this.khcountObj[0][1].life, name: "已完成客户" },
              { value: this.khcountObj[0][0].life, name: "意向客户" },
            ],
            label: {
              formatter: "{b}: {d}%",
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            // 在这里添加标题
            title: {
              text: "总量",
              left: "center",
              top: "center",
              textStyle: {
                fontSize: 14,
              },
            },
            center: ["50%", "50%"], // 中心位置
          },
        ],
        graphic: {
          type: "text",
          left: "center",
          top: "center",
          style: {
            text: "总量" + allAmount, // 要显示的文本
            textAlign: "center",
            fill: "#000", // 文本颜色
            fontSize: 20,
            // 其他样式...
          },
        },
      };
      option && myChart.setOption(option);
    },
    // 客户数量
    getKhcount() {
      this.$http.post("/AgentPlatform/Khcount", {}).then((res) => {
        if (res.data.code == 200) {
          this.khcountObj = res.data.data;
        }
      });
    },
    // 待结安装费
    getDzzf() {
      this.$http.post("/AgentPlatform/Dzzf", {}).then((res) => {
        if (res.data.code == 200) {
          this.DzzfObj = res.data.data;
        }
      });
    },
    // 代理商数量
    getDlcount() {
      this.$http.post("/AgentPlatform/Dlcount", {}).then((res) => {
        if (res.data.code == 200) {
          this.dlcountObj = res.data.data[0];
        }
      });
    },
    // 电站数据
    getDscount() {
      this.$http.post("/AgentPlatform/Dscount", {}).then((res) => {
        if (res.data.code == 200) {
          this.dscountObj = res.data.data[0];
        }
      });
    },
    // 客户统计
    getkhzy() {
      this.$http.post("/AgentPlatform/khzy", this.queryInfo).then((res) => {
        if (res.data.code == 200) {
          this.khzyObj = res.data.data;
        }
      });
    },
    // 订单数量/月/日查询
    getDzcountsm() {
      let data = { dayandmonth: this.dayandmonth };
      data.Starttime = this.salesObj.Endtime ? this.salesObj.Endtime[0] : "";
      data.Endtime = this.salesObj.Endtime ? this.salesObj.Endtime[1] : "";
      this.$http.post("/AgentPlatform/Dzcountsm", data).then((res) => {
        if (res.data.code == 200) {
          this.getDzcountsmList = res.data.data;
          this.drawChart();
        }
      });
    },
    // 订单总金额/月/日查询
    getDzcounmoy() {
      let data = { dayandmonth: this.dayandmonth };
      data.Starttime = this.salesObj.Endtime ? this.salesObj.Endtime[0] : "";
      data.Endtime = this.salesObj.Endtime ? this.salesObj.Endtime[1] : "";
      this.$http.post("/AgentPlatform/Dzcounmoy", data).then((res) => {
        if (res.data.code == 200) {
          this.getDzcountsmList = res.data.data;
          this.drawChart();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.userManage {
  padding: 7px;

  // background-color: #F5F7F9;
  .manage {
    .cardUser {
      height: 110px;
      border-radius: 10px;

      .cardUserItem {
        display: flex;

        .imgAll {
        }

        .cardUserItemshu {
          display: flex;
        }

        .commonUser {
          font-size: 11px;
          margin-top: 7px;
          color: #757575;
        }
      }
    }
  }

  .uickAccess {
    .uickAccessCard {
      margin-top: 7px;
      width: 100%;
      border-radius: 10px;

      .uickAccessItem {
        // height: 30px;
        margin: 10px 10px;
        width: 20%;
        background-color: #e8f3fc;
        border-radius: 10px;
      }
    }
  }

  .statisticalChart {
    margin-top: 7px;
    width: 100%;
    display: flex;

    .salesStatement {
      position: relative;
      border-radius: 10px;
      flex: 1.2;

      .dateYue {
        display: flex;
        position: absolute;
        top: 40px;
        right: 50px;

        .dateitem {
          // margin-top: 5px;
          margin-right: 5px;
          text-align: center;
          width: 25px;
          height: 30px;
          line-height: 30px;
        }

        .active {
          border-radius: 4px;
          background-color: #e3e7fa;
        }
      }
    }

    /deep/ .el-tabs__nav-wrap::after {
      position: static !important;
      width: 0 !important;
      background-color: #fff !important;
    }

    .customerStatistics {
      margin-left: 10px;
      border-radius: 10px;
      flex: 0.8;
    }
  }
  /deep/ .el-card__body {
    padding: 10px;
  }
}
</style>
