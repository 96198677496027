import { render, staticRenderFns } from "./agentIndex.vue?vue&type=template&id=244a9c5c&scoped=true"
import script from "./agentIndex.vue?vue&type=script&lang=js"
export * from "./agentIndex.vue?vue&type=script&lang=js"
import style0 from "./agentIndex.vue?vue&type=style&index=0&id=244a9c5c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "244a9c5c",
  null
  
)

component.options.__file = "agentIndex.vue"
export default component.exports